module.exports = {
    driverOptions: {
        clusterName: {
            label: '集群名称',
            type: "input",
            name: "clusterName",
            placeholder: '',
            rules: [{ required: true, message: '请填写集群名' },
            { pattern: /^[a-z][a-z0-9-]{0,54}[a-z0-9]$|^[a-z]$/gm, message: '以小写字母开头,由小写字母、数字、中划线(-)组成,长度范围1-56位,且不能以中划线(-)结尾' }],
        },
        serviceCidr: {
            label: 'serviceCidr',
            type: "input",
            name: "serviceCidr",
            placeholder: '',
            rules: [],
        },
        kubernetesVersion: {
            label: '版本',
            type: "select",
            name: "kubernetesVersion",
            placeholder: '请选择版本',
            rules: [{ required: true, message: '请填写kubernetes版本' }],
        },
        clusterSpec: {
            label: '集群管理规模',
            type: "select",
            name: "clusterSpec",
            placeholder: '请选择集群管理规模',
            rules: [{ required: true, message: '请选择集群管理规模' }],
        },
        clusterType: {
            label: '集群类型',
            type: "select",
            name: "clusterType",
            placeholder: '请选择集群类型',
            rules: [{ required: true, message: '请选择集群类型' }],
        },
        // projectId: {
        //     label: '企业projectId',
        //     type: "input",
        //     name: "projectId",
        //     placeholder: '',
        //     rules: [{ required: true, message: '请填写企业projectId' }],
        // },
        regionId: {
            label: '区域',
            type: "select",
            name: "regionId",
            placeholder: '请选择区域',
            rules: [{ required: true, message: '请选择区域' }],
        },
        vpcID: {
            label: 'vpcID',
            type: "input",
            name: "vpcID",
            placeholder: '',
            rules: [{ required: true, message: '请填写vpcId' }],
        },
        // serviceCidr: {
        //     label: '子网',
        //     type: "select",
        //     name: "serviceCidr",
        //     placeholder: '请选择子网',
        //     rules: [{ required: true, message: '请选择子网' }],
        // },
        serviceCidr: {
            label: '子网',
            type: "input",
            name: "serviceCidr",
            placeholder: '',
            rules: [{ required: true, message: '请填写serviceCidr' }],
        },

        numOfNodes: {
            label: 'numOfNodes',
            type: "input",
            name: "numOfNodes",
            placeholder: '',
            rules: [{ required: true, message: '请填写numOfNodes' }],
        },
        vswitchIDS: {
            label: 'vswitchIDS',
            type: "inlineInput",
            name: "vswitchIDS",
            placeholder: '',
            rules: [{ required: true, message: '请添加至少一个vswitchIDS' }],
        },
        natGateway: {
            label: 'natGateway',
            type: "radio-group",
            name: "natGateway",
            placeholder: '',
            rules: [{ required: true, message: '请填写natGateway' }],
        },
        disableRollback: {
            label: 'disableRollback',
            type: "radio-group",
            name: "disableRollback",
            placeholder: '',
            rules: [{ required: true, message: '请填写disableRollback' }],
        },
        loadBalancerSpec: {
            label: 'loadBalancerSpec',
            type: "select",
            name: "loadBalancerSpec",
            placeholder: '请选择loadBalancerSpec',
            rules: [{ required: true, message: '请填loadBalancerSpec' }],
        },
    },
    nodesOptions://为方便取每个节点格式 只取其一个元素所需的对象
    {
        cardType: {
            formType: "radio-group",
            label: 'cardType',
            name: 'cardType',
            rules: [{ required: true, message: '请选择cardType', trigger: 'blur' }],
            type: 'text',
        },

        // cloud: {
        //     model: '',
        //     formType: "input",
        //     type: "cloud",
        //     label: "cloud",
        //     name: "cloud",
        //     placeholder: '',
        //     rules: [{ required: true, message: '请填写cloud' }],
        // },
        nodePoolName: {
            label: 'nodePoolName',
            type: "nodePoolName",
            formType: "input",
            name: "nodePoolName",
            placeholder: '',
            rules: [{ required: true, message: '请填写nodePoolName' }, {
                pattern: /^[a-z][a-z0-9-]{0,54}[a-z0-9]$|^[a-z]$/gm,
                message: '以小写字母开头,由小写字母、数字、中划线(-)组成,长度范围1-56位,且不能以中划线(-)结尾。'
            }],
            display: true,
        },
        nodeNums: {
            model: '',
            formType: "input",
            label: "nodeNums",
            type: "nodeNums",
            name: "nodeNums",
            placeholder: '',
            rules: [{ required: true, message: '请填写nodeNums' },
            { pattern: /^[1-3]$/gm, message: '请正确输入节点数(1至3个)' }],
        },
        autoScalingMaxInstances: {
            model: '',
            label: "autoScalingMaxInstances",
            type: "autoScalingMaxInstances",
            name: "autoScalingMaxInstances",
            formType: "input",
            placeholder: '',
            rules: [{ required: true, message: '请填写autoScalingMaxInstances' },
            { pattern: /^[1-3]$/gm, message: '请正确输入最大节点数(1至3个)' }],
        },
        autoScalingMinInstances: {
            model: '',
            label: "autoScalingMinInstances",
            type: "autoScalingMinInstances",
            name: "autoScalingMinInstances",
            formType: "input",
            placeholder: '',
            rules: [{ required: true, message: '请填写autoScalingMinInstances' }
            ,{ pattern: /^[1-3]$/gm, message: '请正确输入最小节点数(1至3个)' }],
        },
        // nodepoolInfoResourceGroupID: {
        //     model: '',
        //     formType: "input",
        //     label: "nodepoolInfoResourceGroupID",
        //     type: "nodepoolInfoResourceGroupID",
        //     name: "nodepoolInfoResourceGroupID",
        //     placeholder: '',
        //     rules: [{ required: true, message: '请填写nodepoolInfoResourceGroupID' }],
        // },
        // nodepoolInfoType: {
        //     model: '',
        //     formType: "input",
        //     label: "nodepoolInfoType",
        //     type: "nodepoolInfoType",
        //     name: "nodepoolInfoType",
        //     placeholder: '',
        //     rules: [{ required: true, message: '请填写nodepoolInfoType' }],
        // },
        nodeLabels: {
            label: 'nodeLabels',
            formFlag: true,
            title: 'nodeLabels',
            formType: 'inlineInput',
            name: 'nodeLabels',
            key: {
                label: '',
                type: "nodeLabelsKey",
                name: "nodeLabelsKey",
                placeholder: 'key',
                // rules: [{ required: true, message: '请填写key' }],
                rules: []
            },
            value: {
                label: '',
                type: "nodeLabelsValue",
                name: "nodeLabelsValue",
                placeholder: 'value',
                // rules: [{ required: true, message: '请填写value' }],
                rules: []
            },
            display: true,
        },
        spotPriceLimit: {
            formFlag: true,
            label: 'spotPriceLimit',
            title: 'spotPriceLimit',
            name: 'spotPriceLimit',
            formType: 'inlineInput',
            key: {
                model: '',
                type: "priceLimit",
                name: "priceLimit",
                placeholder: 'priceLimit',
                // rules: [{ required: true, message: '请填写key' }],
                rules: []
            },
            value: {
                model: '',
                type: "instanceType",
                name: "instanceType",
                placeholder: 'instanceType',
                // rules: [{ required: true, message: '请填写value' }],
                rules: []
            },
        },
        resourceTags: {
            formFlag: true,
            label: 'resourceTags',
            title: 'resourceTags',
            name: 'resourceTags',
            formType: 'inlineInput',
            key: {
                model: '',
                type: "resourceTagsKey",
                name: "resourceTagsKey",
                placeholder: 'key',
                // rules: [{ required: true, message: '请填写key' }],
                rules: []
            },
            value: {
                model: '',
                type: "resourceTagsValue",
                name: "resourceTagsValue",
                placeholder: 'value',
                // rules: [{ required: true, message: '请填写value' }],
                rules: []
            },
        },
        // enableAutoScaling: {
        //     model: '',
        //     label: "enableAutoScaling",
        //     type: "enableAutoScaling",
        //     name: "enableAutoScaling",
        //     formType: "input",
        //     placeholder: '',
        //     rules: [{ required: true, message: '请填写enableAutoScaling' }],
        // },
        autoScalingType: {
            model: '',
            label: "自动伸缩类型",
            type: "autoScalingType",
            name: "autoScalingType",
            formType: "select",
            placeholder: '',
            rules: [{ required: true, message: '请选择自动伸缩类型' }],
        },
        instance_charge_type: {
            model: '',
            label: "instance_charge_type",
            type: "instance_charge_type",
            name: "instance_charge_type",
            formType: "radio-group",
            placeholder: '',
            rules: [{ required: true, message: '请填写instance_charge_type' }],
        },
        // soc_enabled: {
        //     model: '',
        //     label: "soc_enabled",
        //     type: "soc_enabled",
        //     name: "soc_enabled",
        //     formType: "input",
        //     placeholder: '',
        //     rules: [{ required: true, message: '请填写soc_enabled' }],
        // },
        // cis_enabled: {
        //     model: '',
        //     label: "cis_enabled",
        //     type: "cis_enabled",
        //     name: "cis_enabled",
        //     formType: "input",
        //     placeholder: '',
        //     rules: [{ required: true, message: '请填写cis_enabled' }],
        // },

        scalingGroupSpotStrategy: {
            model: '',
            label: "scalingGroupSpotStrategy",
            type: "scalingGroupSpotStrategy",
            name: "scalingGroupSpotStrategy",
            formType: "select",
            placeholder: '',
            rules: [{ required: true, message: '请选择scalingGroupSpotStrategy' }],
        },

        // dataDisks: {
        //     formFlag: true,
        //     title: 'dataDisks',
        //     label: 'dataDisks',
        //     type: "dataDisks",
        //     formType: "checkbox-group",
        //     name: "dataDisks",
        //     placeholder: '请选择dataDisks',
        //     rules: [{ required: true, message: '请填写dataDisks' }],
        //     display: true,
        //     // rules: []
        // },
        scalingGroupInstanceTypes: {
            formFlag: true,
            title: 'scalingGroupInstanceTypes',
            label: 'scalingGroupInstanceTypes',
            type: "scalingGroupInstanceTypes",
            formType: "checkbox-group",
            name: "scalingGroupInstanceTypes",
            placeholder: '请选择scalingGroupInstanceTypes',
            rules: [{ required: true, message: '请填写scalingGroupInstanceTypes' }],
            display: true,
            // rules: []
        },
        // scalingGroupVswitchIds: {
        //     formFlag: true,
        //     title: 'scalingGroupVswitchIds',
        //     label: 'scalingGroupVswitchIds',
        //     type: "scalingGroupVswitchIds",
        //     formType: "checkbox-group",
        //     name: "scalingGroupVswitchIds",
        //     placeholder: '请选择scalingGroupVswitchIds',
        //     rules: [{ required: true, message: '请填写scalingGroupVswitchIds' }],
        //     display: true,
        //     // rules: []
        // },
        onDemandBaseCapacity: {
            model: '',
            type: "onDemandBaseCapacity",
            name: "onDemandBaseCapacity",
            label: "onDemandBaseCapacity",
            formType: "input",
            placeholder: '',
            rules: [{ required: true, message: 'onDemandBaseCapacity' },{ pattern: /^[1-9]$/gm, message: '请正确输入onDemandBaseCapacity' }],
        },
        onDemandPercentageAboveBaseCapacity: {
            model: '',
            type: "onDemandPercentageAboveBaseCapacity",
            label: "onDemandPercentageAboveBaseCapacity",
            name: "onDemandPercentageAboveBaseCapacity",
            formType: "input",
            placeholder: '',
            rules: [{ required: true, message: '请填写onDemandPercentageAboveBaseCapacity' },{ pattern: /^[1-9]$/gm, message: '请正确输入onDemandPercentageAboveBaseCapacity' }],
        },
        scalingGroupSecurityGroupIds: {
            model: '',
            type: "scalingGroupSecurityGroupIds",
            label: "scalingGroupSecurityGroupIds",
            name: "scalingGroupSecurityGroupIds",
            formType: "input",
            placeholder: '',
            rules: [],
        },
        systemDiskCategory: {
            model: '',
            type: "systemDiskCategory",
            label: "systemDiskCategory",
            name: "systemDiskCategory",
            formType: "select",
            placeholder: '',
            undisplay:true,
            rules: [{ required: true, message: '请填写systemDiskCategory' }],
        },
        systemDiskSize: {
            model: '',
            type: "systemDiskSize",
            label: "systemDiskSize",
            name: "systemDiskSize",
            formType: "input",
            placeholder: '',
            undisplay:true,
            rules: [{ required: true, message: '请填写systemDiskSize' },
            { pattern: /(^[4-9][0-9]{1}$)|(^[1-4][0-9]{2}$)/, message: '请正确输入systemDiskSize(40-500)' }],
        },

        // systemDiskPerformanceLevel: {
        //     model: '',
        //     type: "systemDiskPerformanceLevel",
        //     label: "systemDiskPerformanceLevel",
        //     name: "systemDiskPerformanceLevel",
        //     formType: "input",
        //     placeholder: '',
        //     undisplay:true,
        //     rules: [],
        // },
        scalingGroupIsEnterpriseSecurityGroup: {
            model: '',
            type: "scalingGroupIsEnterpriseSecurityGroup",
            label: "scalingGroupIsEnterpriseSecurityGroup",
            name: "scalingGroupIsEnterpriseSecurityGroup",
            formType: "input",
            placeholder: '',
            undisplay:true,
            rules: [{ required: true, message: '请填写scalingGroupIsEnterpriseSecurityGroup' }],
        },
        // scalingGroupPlatform: {
        //     model: '',
        //     type: "scalingGroupPlatform",
        //     label: "scalingGroupPlatform",
        //     name: "scalingGroupPlatform",
        //     formType: "input",
        //     placeholder: '',
        //     undisplay:true,
        //     rules: [{ required: true, message: '请填写scalingGroupPlatform' }],
        // },
        // image_type: {
        //     model: '',
        //     type: "image_type",
        //     label: "image_type",
        //     name: "image_type",
        //     formType: "input",
        //     placeholder: '',
        //     undisplay:true,
        //     rules: [{ required: true, message: '请填写image_type' }],
        // },
        kubernetesConfigRuntimeVersion: {
            model: '',
            type: "kubernetesConfigRuntimeVersion",
            label: "kubernetesConfigRuntimeVersion",
            name: "kubernetesConfigRuntimeVersion",
            formType: "input",
            placeholder: '',
            undisplay:true,
            rules: [{ required: true, message: '请填写kubernetesConfigRuntimeVersion' }],
        },
        kubernetesConfigRuntime: {
            model: '',
            type: "kubernetesConfigRuntime",
            label: "kubernetesConfigRuntime",
            name: "kubernetesConfigRuntime",
            formType: "input",
            placeholder: '',
            undisplay:true,
            rules: [{ required: true, message: '请填写kubernetesConfigRuntime' }],
        },
    },
    nodesCommonOptions: {
        cloud: {
            label: 'cloud',
            // type: "cloud",
            type: "radio-group",
            name: "cloud",
            placeholder: '',
            rules: [{ required: true, message: '请填写cloud' }],
            // display: true,
        },
        workerVswitchIDS: {
            label: 'workerVswitchIDS',
            type: "inlineInput",
            name: "workerVswitchIDS",
            placeholder: '',
            rules: [{ required: true, message: '请添加workerVswitchIDS' }],
        },
        workerInstanceTypes: {
            label: 'workerInstanceTypes',
            type: "input",
            name: "workerInstanceTypes",
            placeholder: '',
            rules: [{ required: true, message: '请填写workerInstanceTypes' }],
        },
        workerSystemDiskCategory: {
            label: 'workerSystemDiskCategory',
            type: "input",
            name: "workerSystemDiskCategory",
            placeholder: '',
            rules: [{ required: true, message: '请填写workerSystemDiskCategory' }],
        },
        workerSystemDiskSize: {
            label: 'workerSystemDiskSize',
            type: "input",
            name: "workerSystemDiskSize",
            placeholder: '',
            rules: [{ required: true, message: '请填写workerSystemDiskSize' }],
        },
        workerInstanceChargeType: {
            label: 'workerInstanceChargeType',
            type: "radio-group",
            name: "workerInstanceChargeType",
            placeholder: '',
            rules: [{ required: true, message: '请填写workerInstanceChargeType' }],
        },
        loginTypes: {
            label: 'loginTypes',
            formType: "radio-group",
            name: "loginTypes",
            rules: [{ required: true, message: '请填写loginTypes' }],
            display: true,
        },
        loginKeyPair: {
            label: 'loginKeyPair',
            type: "input",
            name: "loginKeyPair",
            placeholder: '',
            rules: [{ required: true, message: '请填写loginKeyPair' }],
        },
        loginPassword: {
            label: 'loginPassword',
            type: "password",
            name: "loginPassword",
            placeholder: '',
            rules: [{ required: true, message: '请填写loginPassword',trigger: 'blur',  },
            { pattern: /^(?![A-Za-z]+$)(?![A-Z\d]+$)(?![A-Z\W]+$)(?![a-z\d]+$)(?![a-z\W]+$)(?![\d\W]+$)\S{8,30}$/gm, message: '密码规则为8~30个字符，且至少同时包含三项（大小写字母、数字和特殊符号）' }],
        },
        resourceGroupID :{
            label: 'resourceGroupID',
            type: "input",
            name: "resourceGroupID",
            placeholder: '',
            rules: [{ required: true, message: '请填写resourceGroupID' }],
        }
    },
    accessData: {
        accessKeyID: {
            model: '',
            type: "text",
            name: "accessKeyID",
            placeholder: '',
            rules: [{ required: true, message: '请填写accessKeyID', trigger: 'change', }],
        },
        accessKeySecret: {
            model: '',
            type: "password",
            name: "accessKeySecret",
            placeholder: '',
            rules: [{ required: true, message: '请填写accessKeySecret', trigger: 'change', }],
        },
    }
}