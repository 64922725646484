<template>
  <div class="form ali">
    <el-form :model="accessData" ref="accessData" >
      <el-form-item
        v-for="(item, index) in formObjAccess"
        :key="index"
        :label="item.name"
        :prop="item.name"
        :rules="item.rules"
        :placeholder="item.placeholder"   
        style="margin: 0 auto;margin-bottom:22px;width:400px;" 
      >
        <el-input
          v-model="accessData[item.name]"
          :type="item.type"
        ></el-input>
      </el-form-item>
      <PrevNext
        @prev-click="onPrevClick"
        @next-click="onNextClick('accessData')"
      ></PrevNext>
    </el-form>
  </div>
</template>
<script>
import formObj from "../../../assets/formObj/ali.js";
import PrevNext from "../../Common/PrevNext.vue";
import { PROCESS_ACTIVE } from "../../../assets/constant.js";

export default {
  created() {
    this.$store.dispatch("setProcessActive", PROCESS_ACTIVE.ACCESS);
  },

  beforeDestroy: function () {
    alert("beforeDestroy");
    console.log("beforeDestroy");
  },
  components: {
    PrevNext,
  },
  data() {
    return {
      formObjAccess: formObj.accessData,
      accessData: {
        accessKeyID: this.$store.getters.reqData?.accessKeyID,
        accessKeySecret: this.$store.getters.reqData?.accessKeySecret,
      },
    };
  },

  methods: {
    onPrevClick() {
      this.$router.push("/form/channel");
    },
    onNextClick(accessData) {
      this.$refs[accessData].validate((valid) => {
        if (valid) {
          try {
            this.accessData.clusterInfo = null;
            this.$store.commit("setAccessData", this.accessData);
            console.log("权限完成，即将进入节点创捷阶段...");
            this.$router.push("/form/aliAccess/aliCluster/");
          } catch (error) {
            console.log(error);
            ElMessage({
              showClose: true,
              message: "未知错误，请联系技术人员",
              type: "error",
            });
          }
        } else {
          ElMessage({
            showClose: true,
            message: "输入不符合规则，请重新输入",
            type: "error",
          });
        }
      });
    },
  },
};
</script>
<style scoped>
</style>